<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem class="pre_menu">
          <span @click="onBack">订单查看</span>
        </BreadcrumbItem>
        <BreadcrumbItem>关联订单</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="card__container">
      <div class="card__container__title">关联订单</div>
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :loading="loading"
        :pagination="false"
        rowKey="orderSn"
      >
        <template slot="approvalStatus" slot-scope="text">
          <span
            v-if="
              text === '待审核部审核' ||
                text === '待稽查部审核' ||
                text === '待财务初审' ||
                text === '待财务复审'
            "
            class="status--orange"
          >
            {{ text }}
          </span>
          <span v-else>
            {{ text }}
          </span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a
              v-if="
                (record.orderStatusValue === '待审核部审核' ||
                  record.orderStatusValue === '待稽查部审核' ||
                  record.orderStatusValue === '待财务初审' ||
                  record.orderStatusValue === '待财务复审') &&
                  personnelType === record.currentApprovalPersonnelType &&
                  record.signStatusValue === '已签署'
              "
              @click="onExamine(record.orderSn)"
            >
              审核
            </a>
            <a v-else @click="onDetail(record.orderSn)">查看</a>
          </a-space>
        </template>
      </a-table>
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { checkRelevanceOrder } from "@/services/OrderManageService";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import { tsFormat } from "@/components/DateUtils";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    width: "24%"
  },
  {
    title: "下单机构",
    dataIndex: "purchaserCorporationName",
    width: "15%"
  },
  {
    title: "出厂价总额（元）",
    dataIndex: "factoryTotalPrice",
    width: "10%"
  },
  {
    title: "订单总额",
    dataIndex: "totalPrice",
    width: "10%"
  },
  {
    title: "审核状态",
    dataIndex: "approvalStatus",
    scopedSlots: { customRender: "approvalStatus" },
    width: "12%"
  },
  {
    title: "签署状态",
    dataIndex: "signStatus",
    width: "8%"
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "16%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "5%"
  }
];

export default {
  name: "RelevanceOrder",
  components: {
    BreadcrumbItem,
    Breadcrumb
  },
  data() {
    return {
      tblData: [],
      tblColumns,
      loading: false,
      orderSn: this.$route.params.orderSn,
      tsFormat
    };
  },
  mounted() {
    this.loadOrderList();
  },
  methods: {
    // 返回上一级
    onBack() {
      this.$router.go(-1);
    },
    // 加载关联订单
    loadOrderList() {
      this.loading = true;
      checkRelevanceOrder(this.orderSn)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.loading = false;
            this.tblData = resp.data.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 审核
    onExamine(orderSn) {
      this.$router.push({
        name: "PayOrderDetail",
        params: { orderSn: orderSn, type: "examine" }
      });
    },
    // 查看
    onDetail(orderSn) {
      this.$router.push({
        name: "PayOrderDetail",
        params: { orderSn: orderSn, type: "detail" }
      });
    }
  }
};
</script>

<style scoped>
.card__container__title {
  color: #666666;
  font-weight: bold;
  margin-bottom: 15px;
}

.status--orange {
  color: rgba(255, 134, 0, 1);
}

.pre_menu {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
}

.pre_menu:hover {
  color: #1890ff;
}
</style>
