var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',[_c('div',{staticClass:"nav"},[_c('Breadcrumb',[_c('BreadcrumbItem',{staticClass:"pre_menu"},[_c('span',{on:{"click":_vm.onBack}},[_vm._v("订单查看")])]),_c('BreadcrumbItem',[_vm._v("关联订单")])],1)],1),_c('div',{staticClass:"card__container"},[_c('div',{staticClass:"card__container__title"},[_vm._v("关联订单")]),_c('a-table',{attrs:{"data-source":_vm.tblData,"columns":_vm.tblColumns,"loading":_vm.loading,"pagination":false,"rowKey":"orderSn"},scopedSlots:_vm._u([{key:"approvalStatus",fn:function(text){return [(
            text === '待审核部审核' ||
              text === '待稽查部审核' ||
              text === '待财务初审' ||
              text === '待财务复审'
          )?_c('span',{staticClass:"status--orange"},[_vm._v(" "+_vm._s(text)+" ")]):_c('span',[_vm._v(" "+_vm._s(text)+" ")])]}},{key:"createTime",fn:function(text){return [_vm._v(" "+_vm._s(text ? _vm.tsFormat(text) : "")+" ")]}},{key:"action",fn:function(text, record){return [_c('a-space',[(
              (record.orderStatusValue === '待审核部审核' ||
                record.orderStatusValue === '待稽查部审核' ||
                record.orderStatusValue === '待财务初审' ||
                record.orderStatusValue === '待财务复审') &&
                _vm.personnelType === record.currentApprovalPersonnelType &&
                record.signStatusValue === '已签署'
            )?_c('a',{on:{"click":function($event){return _vm.onExamine(record.orderSn)}}},[_vm._v(" 审核 ")]):_c('a',{on:{"click":function($event){return _vm.onDetail(record.orderSn)}}},[_vm._v("查看")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }